<template>
	<div class="deliveryRoute">
		<div class="table">
			<div class="table-operation">
				<el-button type="warning" @click.stop="addDialog">新增</el-button>
				<el-button type="success" @click.stop="openDeliveryMap">配送地图</el-button>
				<el-button type="info" icon="el-icon-refresh-left" @click.stop="loading = true, getList()"></el-button>
				<span style="color:#F56C6C;padding-left:20px">划定配送区域，在生成配送单时可自动匹配路线，匹配不到使用默认设置的路线</span>
			</div>
			<Table :data="tableData" :total="total" v-loading="loading"
				@size-change="pageNum = $event, loading = true, getList()"
				@current-change="page = $event, loading = true, getList()">
				<el-table-column label="线路名称" prop="title" align="center"></el-table-column>
				<el-table-column label="负责人" prop="contacts" align="center"></el-table-column>
				<el-table-column label="联系电话" prop="phone" align="center"></el-table-column>
				<el-table-column label="参考配送费" prop="fee" align="center"></el-table-column>
				<el-table-column label="操作" align="center" width="160">
					<template slot-scope="scope">
						<el-button type="warning" size="mini"
							@click.stop="dialogVisible = true, dialogType = 2, openDialog(scope.row)">编辑</el-button>
						<el-button type="danger" size="mini" @click.stop="removeFn(scope.row)">删除</el-button>
					</template>
				</el-table-column>
			</Table>
		</div>
		<!-- 新增 -->
		<el-dialog :title="dialogType == 1 ? '新增' : '编辑'" :visible.sync="dialogVisible" :close-on-click-modal="false"
			v-el-drag-dialog width="70%" @close="closeDialog">
			<div style="height:50%;">
				<el-scrollbar style="height:100%">
					<el-form ref="form" :model="form" label-width="100px">
						<el-form-item label="线路名称">
							<el-input v-model="form.title" placeholder="请输入线路名称"></el-input>
						</el-form-item>
						<el-form-item label="负责人">
							<el-input v-model="form.contacts" placeholder="请输入负责人姓名"></el-input>
						</el-form-item>
						<el-form-item label="联系电话">
							<el-input v-model="form.phone" placeholder="请输入联系电话"></el-input>
						</el-form-item>
						<el-form-item label="参考配送费">
							<el-input v-model="form.fee" placeholder="请输入参考配送费"></el-input>
						</el-form-item>
						<el-form-item label="配送区域">
							<el-input placeholder="输入地区名称快速切换地图显示区域" v-model="map_area" style="margin-bottom: 10px;">
								<el-button slot="append" icon="el-icon-search" @click.stop="searchMap">搜索</el-button>
							</el-input>
							<div class="map-content">
								<div id="toolControl">
									<!-- <el-button size="small" type="primary" @click.stop="addWeilan">添加</el-button> -->
									<!-- <el-button size="small" type="warning" @click.stop="editWeilan">编辑</el-button> -->
									<el-button size="small" type="danger" @click.stop="removeWeilan">删除区域</el-button>
								</div>
								<div id="pointsContainer" style="width:100%; height:100%"></div>
							</div>
						</el-form-item>
					</el-form>
				</el-scrollbar>
			</div>
			<div style="color: red;margin-left: 100px;margin-top: 20px;">
				<p>在地图上单击鼠标左键开始绘制配送区域，在地图上双击鼠标左键结束绘制</p>
				<p>在地图上单击 鼠标右键取消上一步操作，选中配送区域可以调整配送区域范围</p>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click.stop="closeDialog(), dialogVisible = false">取 消</el-button>
				<el-button type="warning" :loading="confirmLoading" @click.stop="confirmFn">确 定</el-button>
			</span>
		</el-dialog>
		<!-- 配送地图 -->
		<el-dialog title="配送地图" :visible.sync="dialogDeliveryMap" :close-on-click-modal="false" v-el-drag-dialog
			width="70%" @close="closeDialog">
			<div id="deliveryMap" style="width:100%; height:100%"></div>
			<span slot="footer" class="dialog-footer">
				<el-button @click.stop="closeDialog(), dialogDeliveryMap = false">关闭</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				tableData: [],
				loading: true,
				page: 1,
				pageNum: 10,
				total: 0,
				// 新增、编辑弹窗
				dialogType: 1, //1新增2编辑
				dialogVisible: false,
				form: {
					id: 0,
					title: "",
					contacts: "",
					phone: "",
					fee: 0
				},
				confirmLoading: false,
				//地图
				defaultLat: 36.184384,
				defaultLng: 120.428643,
				map_area: "",
				map: null,
				editor: "",
				editFlag: true,
				mapList: [],
				// 配送地图
				dialogDeliveryMap: false,

			}
		},
		mounted() {
			this.getList()
		},
		methods: {
			getList() {
				this.$http.post(`erp/v1/delivery_line/lst`, {
					page: this.page,
					pageNum: this.pageNum,
				}).then((res) => {
					const {
						code,
						data,
						msg
					} = res.data
					this.loading = false
					if (code == 200) {
						this.tableData = data.rows
						this.total = data.total
					} else {
						this.$message.error(msg);
					}
				});
			},
			searchMap() {
				if (this.map_area == "") {
					this.$message().error("请输入要检索的位置");
					return;
				}
				this.$http.post(`erp/v1/common/search_map`, {
					map_address: this.map_area
				}).then((res) => {
					const {
						code,
						msg,
						data
					} = res.data
					if (code == 200) {
						// 地图
						var center = new TMap.LatLng(data.lat, data.lng)
						this.map.setCenter(center)
					} else {
						this.$message.error(msg);
					}
				});
			},
			// 新增弹窗
			addDialog() {
				this.dialogVisible = true
				this.dialogType = 1
				this.form = {}
				// 地图
				this.editFlag = false;
				this.$nextTick(() => {
					this.initMap(this.defaultLat, this.defaultLng)
				})
			},
			// 编辑弹窗
			openDialog(item) {
				this.form.id = item.id
				this.form.title = item.title
				this.form.contacts = item.contacts
				this.form.phone = item.phone
				this.form.fee = item.fee
				if (item.points != "" && item.points != null) {
					this.editFlag = true
					let arr = []
					let mapArr = JSON.parse(item.points)
					mapArr.paths.forEach(i => {
						arr.push(new TMap.LatLng(i.lat, i.lng));
					})
					this.mapList[0] = {
						id: mapArr.id,
						paths: arr,
						styleId: mapArr.styleId,
					}
				} else {
					this.editFlag = false
				}
				// 地图
				this.$nextTick(() => {
					this.initMap(this.defaultLat, this.defaultLng)
				})
			},
			// 确定
			confirmFn() {
				if (!this.form.title) {
					this.$message.error('请输入线路名称');
					return
				}
				if (!this.form.contacts) {
					this.$message.error('请输入负责人姓名');
					return
				}
				if (this.mapList.length > 0) {
					this.form.points = JSON.stringify(this.mapList[0])
				}
				this.confirmLoading = true
				let url = this.dialogType == 1 ? '/erp/v1/delivery_line/add' : '/erp/v1/delivery_line/modify'
				this.$http.post(`${url}`, this.form).then((res) => {
					const {
						code,
						msg
					} = res.data
					if (code == 200) {
						this.$message.success(msg);
						this.dialogVisible = false
						this.confirmLoading = false
						this.getList()
					} else {
						this.$message.error(msg);
					}
				});
			},
			// 删除
			removeFn(item) {
				this.$confirm('此操作将删除该数据, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$http.post(`erp/v1/delivery_line/delete`, {
						id: item.id
					}).then((res) => {
						const {
							code,
							msg
						} = res.data
						if (code == 200) {
							this.$message.success(msg);
							this.getList()
						} else {
							this.$message.error(msg);
						}
					});
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除'
					});
				});
			},
			initMap(lat, lng) {
				this.map = new TMap.Map(document.getElementById('pointsContainer'), {
					center: new TMap.LatLng(lat, lng),
					zoom: 12,
				});
				this.editor = new TMap.tools.GeometryEditor({
					map: this.map,
					overlayList: [{
						overlay: new TMap.MultiPolygon({
							map: this.map,
							styles: {
								highlight: new TMap.PolygonStyle({
									color: "rgba(255, 255, 0, 0.6)",
								}),
							},
							geometries: this.mapList,
						}),
						id: "polygon",
					}, ],
					actionMode: this.editFlag == true ? TMap.tools.constants.EDITOR_ACTION.INTERACT : TMap.tools
						.constants.EDITOR_ACTION.DRAW,
					activeOverlayId: 'polygon',
					snappable: true,
					selectable: true
				});
				let evtList = ["delete", "adjust", "split", "union", "draw"];
				evtList.forEach((evtName) => {
					this.editor.on(evtName + "_complete", (evtResult) => {
						if (evtName == "draw") {
							//增加
							evtResult.id = this.mapList.length + 1;
							this.mapList.push(evtResult);
						} else if (evtName == "adjust") {
							//拖动修改
							let mapList = this.mapList;
							mapList.forEach((item) => {
								if (item.id == evtResult.id) {
									item.paths = evtResult.paths;
								}
							});
						} else if (evtName == "delete") {
							//删除
							this.mapList = this.mapList.filter(
								(item) => item.id != evtResult[0].id
							);
						}
						this.mapList = Array.from(
							new Set(this.mapList.map((item) => item.id))
						).map((id) => this.mapList.find((item) => item.id === id));
						let arr = [];
						if (this.mapList.length != 0) {
							this.mapList.forEach((item) => {
								arr.push(item.paths);
							});
						}
					});
				});
				// 监听绘制结束事件
				this.editor.on("draw_complete", (geometry) => {
					console.log(geometry, 'geometry');
					this.map.destroy()
					this.editFlag = true;
					this.initMap(this.defaultLat, this.defaultLng)
				});
			},
			// 添加围栏
			addWeilan() {
				if (this.mapList.length <= 0) {
					this.map.destroy()
					this.editFlag = false;
					this.initMap(this.defaultLat, this.defaultLng)
				}
			},
			// 编辑围栏
			editWeilan() {
				this.map.destroy()
				this.editFlag = true;
				this.initMap(this.defaultLat, this.defaultLng)
			},
			// 删除围栏
			removeWeilan() {
				this.mapList = []
				this.map.destroy()
				this.editFlag = false;
				this.initMap(this.defaultLat, this.defaultLng)
			},
			// 关闭地图弹窗
			closeDialog() {
				this.mapList = []
				this.map.destroy()
			},
			// 配送地图
			openDeliveryMap() {
				this.dialogDeliveryMap = true
				this.$http.post(`erp/v1/delivery_line/all`).then((res) => {
					const {
						code,
						data,
						msg
					} = res.data
					if (code == 200) {
						// 坐标打点
						var coordinateArr = []
						// 绘图
						var mapArr = []
						data.forEach(i => {
							i.styleId = 'marker'
							if (i.points != null && i.points != "") {
								mapArr.push(JSON.parse(i.points))
								mapArr.forEach(j => {
									i.lat = j.paths.reduce((sum, coord) => sum + coord.lat, 0) / j
										.paths.length;
									i.lng = j.paths.reduce((sum, coord) => sum + coord.lng, 0) / j
										.paths.length;
								})
								coordinateArr.push(i)
							}

						})

						coordinateArr.forEach(i => {
							i.position = new TMap.LatLng(i.lat, i.lng)
							i.content = i.title
						})
						this.map = new TMap.Map(document.getElementById('deliveryMap'), {
							center: new TMap.LatLng(this.defaultLat, this.defaultLng),
							zoom: 12,
						});
						this.editor = new TMap.MultiMarker({
							id: 'marker-layer',
							map: this.map,
							styles: {
								marker: new TMap.MarkerStyle({
									direction: 'top'
								})
							},
							geometries: coordinateArr,
						});
						this.editor = new TMap.tools.GeometryEditor({
							map: this.map,
							overlayList: [{
								overlay: new TMap.MultiPolygon({
									map: this.map,
									styles: {
										highlight: new TMap.PolygonStyle({
											color: "rgba(255, 255, 0, 0.6)",
										}),
									},
									geometries: mapArr,
								}),
								id: "polygon",
							}, ]
						});
					} else {
						this.$message.error(msg);
					}
				});
			},
		},
	}
</script>

<style lang="scss" scoped>
	.map-content {
		position: relative;

		#toolControl {
			position: absolute;
			top: 10px;
			left: 0px;
			right: 0px;
			margin: auto;
			width: 252px;
			z-index: 1001;
		}
	}
</style>